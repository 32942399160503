import React from 'react'
import {connect} from 'react-redux'
import find from 'lodash/find'
import {Waypoint} from 'react-waypoint'
import {ScrollElement} from 'react-scroll'
import {setActive} from '../../ducks/nav'

const triggerActiveSection = {
  topOffset: 10 + '%',
  bottomOffset: 60 + '%'
}

const ScrollSection = ScrollElement(({children}) => (
  <div className="scroll-section">
    {children}
  </div>
))

const Section = ({name, className = '', children, colour, setActive, ...props}) => (
  <ScrollSection>
    <Waypoint
      {...triggerActiveSection}
      scrollableAncestor={window}
      onEnter={() => setActive(name)}>
      <section className={[name, className, colour].filter(Boolean).join(' ')}>
        {children}
      </section>
    </Waypoint>
  </ScrollSection>
)

const mapStateToProps = ({nav}, {name}) => ({
  colour: find(nav.items, {name}).colour
})

export default connect(mapStateToProps, {setActive})(Section)
