import {createStore, combineReducers} from 'redux'
import nav from './ducks/nav'
import timeline from './ducks/timeline'
import galleries from './ducks/galleries'
import lightbox from './ducks/lightbox'
import carRace from './ducks/carRace'
import keyFacts from './data/keyFacts'
import employment from './data/employment'
import education from './data/education'

const reducer = combineReducers({
  nav,
  timeline,
  galleries,
  lightbox,
  carRace,
  keyFacts: () => keyFacts,
  employment: () => employment,
  education: () => education,
})

export default createStore(reducer, undefined)
