import assign from 'lodash/assign'
import initialState from '../data/lightbox'

export default (state = initialState, action) => {
  switch(action.type) {
    case 'LIGHTBOX_OPEN':
      return assign({}, state, {
        open: true,
        galleryName: action.name
      })
    case 'LIGHTBOX_CLOSE':
      return assign({}, state, {
        open: false,
        galleryName: null
      })
    default:
      return state
  }
}

export const open = (name) => ({
  type: 'LIGHTBOX_OPEN',
  name
})

export const close = () => ({
  type: 'LIGHTBOX_CLOSE'
})
