export default [
  {
    name: 'Stewarton Academy',
    startDate: '1995-08-01',
    endDate: '2001-05-01',
    examResults: [
      {
        name: 'Advanced Higher Religious Studies',
        grade: 'B',
      },
      {
        name: 'Higher Graphic Communication',
        grade: 'B',
      },
      {
        name: 'Higher Modern Studies',
        grade: 'C',
      },
      {
        name: 'Higher Maths',
        grade: 'C',
      },
      {
        name: 'Higher Music',
        grade: 'C',
      },
      {
        name: 'SCE Higher English',
        grade: 'B',
      },
      {
        name: 'Intermediate 2 Computing',
        grade: 'A',
      },
    ]
  },
]
