export default {
  'widescreen': {
    focusedIdx: 0,
    items: [
      {
        img: '1.jpg',
        text: 'Tile view'
      },
      {
        img: '2.jpg',
        text: 'File importer (render)'
      },
    ]
  },
  'inhouse': {
    focusedIdx: 0,
    items: [
      {
        img: '1.jpg',
        text: 'The house... above the fold'
      },
    ]
  },
  // '100mondays': {
  //   focusedIdx: 0,
  //   items: [
  //     {
  //       img: '1.png',
  //       text: ''
  //     },
  //     {
  //       img: '1.png',
  //       text: ''
  //     },
  //     {
  //       img: '1.png',
  //       text: ''
  //     },
  //   ]
  // },
  // 'todos': {
  //   focusedIdx: 0,
  //   items: [
  //     {
  //       img: '1.png',
  //       text: ''
  //     },
  //     {
  //       img: '1.png',
  //       text: ''
  //     },
  //     {
  //       img: '1.png',
  //       text: ''
  //     },
  //   ]
  // },
}
