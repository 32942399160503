import React from 'react'
import './index.sass'

const Ticks = ({items, focusedIdx, isDecrement, onClick}) => (
  <div className="ticks">
    <ul>
      {items.map((item, i) => (
        <li key={i}
            className={getItemClasses(focusedIdx, i, isDecrement)}
            onClick={() => onClick && onClick(i)}>
          {item.year}
        </li>
      ))}
    </ul>
  </div>
)

function getItemClasses(focusedIdx, idx, isDecrement) {
  if(idx === focusedIdx - 1)
    return isDecrement ? 'next' : 'prev'
  else if(idx === focusedIdx)
    return 'active'
  else if(idx === focusedIdx + 1)
    return isDecrement ? 'prev' : 'next'
  return ''
}

export default Ticks
