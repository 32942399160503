import assign from 'lodash/assign'
import initialState from '../data/nav'

export default (state = initialState, action) => {
  switch(action.type) {
    case 'NAV_SET_ACTIVE':
      return assign({}, state, {active: action.active})
    case 'NAV_OPEN':
      return assign({}, state, {opened: true})
    case 'NAV_CLOSE':
      return assign({}, state, {opened: false})
    case 'NAV_HAMBURGER_VISIBLE':
      return assign({}, state, {hamburgerVisible: true})
    case 'NAV_HAMBURGER_HIDDEN':
      return assign({}, state, {hamburgerVisible: false})
    default:
      return state
  }
}

export const open = () => ({
  type: 'NAV_OPEN'
})

export const close = () => ({
  type: 'NAV_CLOSE'
})

export const setActive = (active) => ({
  type: 'NAV_SET_ACTIVE',
  active
})

export const hamburgerVisible = () => ({
  type: 'NAV_HAMBURGER_VISIBLE'
})

export const hamburgerHidden = () => ({
  type: 'NAV_HAMBURGER_HIDDEN'
})
