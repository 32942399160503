import React from 'react'
import {render} from 'react-dom'
import {Provider} from 'react-redux'
import {HelmetProvider} from 'react-helmet-async'
import store from './store'
import App from './App'

render(
  <Provider store={store}>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </Provider>,
  document.getElementById('root')
)
