import assign from 'lodash/assign'
import initialState from '../data/galleries'

export default (state = initialState, action) => {
  switch(action.type) {
    case 'GALLERY_SET':
    case 'GALLERY_NEXT':
    case 'GALLERY_PREV':
      return assign({}, state, {
        [action.galleryName]: galleryReducer(state[action.galleryName], action)
      })
    default:
      return state
  }
}

function galleryReducer(state, action) {
  switch(action.type) {
    case 'GALLERY_SET':
      return assign({}, state, {focusedIdx: action.focusedIdx})
    case 'GALLERY_NEXT':
      return assign({}, state, {focusedIdx: state.focusedIdx + 1})
    case 'GALLERY_PREV':
      return assign({}, state, {focusedIdx: state.focusedIdx - 1})
    default:
      return state
  }
}

export const prev = (galleryName) => ({
  type: 'GALLERY_PREV',
  galleryName
})

export const next = (galleryName) => ({
  type: 'GALLERY_NEXT',
  galleryName
})

export const set = (galleryName, focusedIdx) => ({
  type: 'GALLERY_SET',
  galleryName,
  focusedIdx
})
