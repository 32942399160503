import React, {Component} from 'react'
import Canvas, {toRadians, easeOutQuint as tween} from '../Canvas'

let counter = 0
const uniqueRef = () => `shutter-${counter++}`

const closedAngle = -10
const openedAngle = 125
const closedDiameter = 2
const openedDiameter = 100
const numBlades = 5
const bladeSize = 200
const rotationStep = toRadians(360 / numBlades)
const skewX = toRadians(6)
const skewY = toRadians(12)

export default class Shutter extends Component {
  constructor(...props) {
    super(...props)
    this.timer = 0
    this.angle = openedAngle
    this.diameter = openedDiameter
    this.closing = false
    this.animate = this.animate.bind(this)
    this.onResize = this.onResize.bind(this)
    this.uniqueRef = uniqueRef()
  }

  componentDidMount() {
    const canvas = this.refs[this.uniqueRef]
    this.ctx = canvas.getCtx()
    this.dimensions = canvas.getDimensions()
  }

  componentDidUpdate(prevProps) {
    const {open} = this.props
    if(open === prevProps.open) return
    this.closing = open

    if(this.closing) {
      this.animate()
    } else {
      setTimeout(this.animate, 400)
    }
  }

  animate(delta) {
    if(delta) {
      if(this.timer === 0) {
        this.start = delta
        this.timer = (delta - this.start) + 0.0001
      } else {
        this.timer = delta - this.start
      }
    }

    if(this.timer > this.props.duration) {
      if(this.closing) {
        this.angle = closedAngle
        this.diameter = closedDiameter
      } else {
        this.angle = openedAngle
        this.diameter = openedDiameter
      }
      this.draw(this.angle, this.diameter)
      this.timer = 0
      return
    }

    const frame = this.timer / this.props.duration
    const speed = tween(this.closing ? frame : 1 - frame)
    const angleTravel = (openedAngle - closedAngle) * speed
    const diameterTravel = (openedDiameter - closedDiameter) * speed
    this.angle = openedAngle - angleTravel
    this.diameter = openedDiameter - diameterTravel

    this.draw(this.angle, this.diameter)
    requestAnimationFrame(this.animate)
  }

  draw(angle, diameter) {
    const {width, height} = this.dimensions
    const blade = this.vw(bladeSize)
    const opening = this.vw(diameter)

    this.ctx.fillStyle = '#111'
    this.ctx.strokeStyle = '#161616'
    this.ctx.lineWidth = width * 0.02
    this.ctx.setTransform(1, 0, 0, 1, width / 2, height / 2)
    this.ctx.clearRect(-width / 2, -height / 2, width, height)
    this.ctx.rotate(toRadians(angle))

    for(let i = 0; i < numBlades; ++i) {
      this.ctx.rotate(rotationStep)
      this.ctx.save()
      this.ctx.transform(1, skewX, skewY, 1, opening * 0.9, -opening)
      this.ctx.fillRect(-blade, -blade, blade, blade)
      this.ctx.strokeRect(-blade, -blade, blade, blade)
      this.ctx.restore()
    }
  }

  vw(val) {
    const {width} = this.dimensions
    return (width / 100) * val
  }

  vh(val) {
    const {height} = this.dimensions
    return (height / 100) * val
  }

  onResize(dimensions) {
    this.dimensions = dimensions
    this.draw(this.angle, this.diameter)
  }

  render() {
    return <Canvas className="shutter" ref={this.uniqueRef} onResize={this.onResize} />
  }
}

Shutter.defaultProps = {
  duration: 400
}
