import React from 'react'
import Section from '../../containers/ScrollSection'
import KPVLab from './KPVLab'
import Inhouse from './Inhouse'
import Arkade from './Arkade'
import AlienationDigital from './AlienationDigital'

const Employment = () => (
  <Section name="employment">
    <h2>Relevant employment history</h2>
    <KPVLab />
    <Inhouse />
    <Arkade />
    <AlienationDigital />
  </Section>
)

export default Employment
