import React from 'react'
import Section from '../../containers/ScrollSection'
import Gallery from '../../containers/Gallery'

const PersonalProjects = () => (
  <Section name="personal-projects">
    <h2>Personal Projects</h2>
    <p className="subtle">I like to have multiple personal projects on the go which I use as a testbed to explore and learn new things.  These are not designed to be publicly available, but I'm happy to provide demos via a screenshare video on request!</p>

    <h3>Money app</h3>
    <p>I find financial stuff exceedingly boring and as a result I was very disorganised with my finances.  Since having a child and buying a house, it's become crucial for us to plan and organise our finances efficiently, so I built an app to automate as much of this as I can.</p>
    <p>Our bank accounts are with Monzo, which offers a real time API of all our transactions.  Using this API, this app automatically categorises our transactions and visualises them in thoughtful ways.  At a glance, I can check to see if I can afford to pay a babysitter and go out for dinner!</p>

    <h3>Photo editor and organiser</h3>
    <p>Starting off as an excuse to learn more about writing <strong>WebGL GLSL shaders</strong> for image effects, this project then extended to include an interface for rotate, crop and undo.</p>
    <p>Recently I've been working to expand this project to organise, safely backup, and batch edit my family's growing photo collection.</p>

    <h3>100mondays</h3>
    {/* <Gallery name="100mondays" /> */}
    <p>I spent 2 years travelling round the world with my wife Sazia.  Our families wanted us to check in with them when we moved on to a new location, so early on I built a small script allowing me to click a button on my phone and it would send an email to a list of contacts with our new location on a map.  With a pretty exhaustive list of all the places we stayed on our trip, I've enjoyed experimenting with the dataset and learned loads about <strong>geo-spatial visualisation</strong>.</p>
    <p>Using the amazing Rome2Rio API, I visualised the public transport routes we took between waypoints using MapboxGL, color-coded by transport type.  Using various other APIs, I added temperarute and altitude data into the mix, which allowed me to calculate cool insights like hottest and coldest moments, and highest and lowest points throughout our trip</p>
    <p>This project is something I'll always come back to as I enjoy remembering those times.  Future plans include integrating our photos, and to send us an email each Monday which tells us where we were in the world on that particular Monday, with photos and location data like temperature, sunrise, sunset, etc.</p>

    <h3>Widescreen</h3>
    <Gallery name="widescreen" />
    <p>When Widescreen is pointed to a folder on my computer with movies in it, it will display the files in a Netflix-ish interface which can be controlled with a remote control.</p>
    <p>Widescreen consists of three main parts: a movie importer which organises files and parses file names and metadata; an <strong>asynchronous task queueing system</strong> which downloads posters, backdrops and metadata from various online sources while adhering to throttle and concurrency usage limits; and a slick <strong>media-rich UI</strong>.  Playing the movie files is offloaded to VLC.</p>

    <h3>Smarthome</h3>
    <p>Last year my wife and I bought an old Victorian house and before long, I started researching how to automate certain household tasks such as heat only one room when I'm working from home in the winter, play BBC Radio 6 in each room, press a button to turn off all the lights before bed.</p>
    <p>This is my first <strong>IoT</strong> project, and I find it really interesting applying software concepts to a physical space, and dipping my toes into the field of electronics.  Still in it's early stages, I currently capture environmental data using a <strong>Raspberry Pi Zero</strong> running Node.js, and I'm learning how to 3D model my house using <strong>Blender</strong> and <strong>Three.js</strong>, which will eventually be the main interface of the project.</p>
  </Section>
)

export default PersonalProjects
