import React from 'react'
import {connect} from 'react-redux'
import Shutter from '../../components/Shutter'
import ImageMorph from '../../components/ImageMorph'
import Rolodex from '../../components/Rolodex'
import {Close} from '../../components/Icons'
import {prev, next} from '../../ducks/galleries'
import {close} from '../../ducks/lightbox'
import './index.sass'

const {PUBLIC_URL} = process.env

const Lightbox = ({galleryName, open, items, focusedIdx, prev, next, close}) => (
  <div className={'lightbox' + (open ? ' open' : '')}>
    <Shutter open={open} />
    <ImageMorph
      basePath={galleryName ? `${PUBLIC_URL}/img/galleries/${galleryName}` : null}
      items={items}
      focusedIdx={focusedIdx}
      backgroundSize="contain"
    />
    <Rolodex
      items={items}
      focusedIdx={focusedIdx}
      prevSlide={() => prev(galleryName)}
      nextSlide={() => next(galleryName)}
    />
    <span className="close" onClick={close}>
      <Close fill="#fff" />
    </span>
  </div>
)

const mapStateToProps = ({lightbox, galleries}) => {
  const {galleryName, open} = lightbox
  let items = []
  let focusedIdx = 0
  if(galleryName) {
    const gallery = galleries[galleryName]
    items = gallery.items
    focusedIdx = gallery.focusedIdx
  }
  return {galleryName, open, items, focusedIdx}
}

export default connect(mapStateToProps, {prev, next, close})(Lightbox)
