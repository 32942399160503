import React from 'react'
import {connect} from 'react-redux'
import ImageMorph from '../../components/ImageMorph'
import Rolodex from '../../components/Rolodex'
import {Fullscreen} from '../../components/Icons'
import {prev, next} from '../../ducks/galleries'
import {open} from '../../ducks/lightbox'
import './index.sass'

const {PUBLIC_URL} = process.env

const Gallery = ({name, items, focusedIdx, prev, next, open}) => (
  <div className="boxout">
    <div className="gallery">
      <ImageMorph
        basePath={`${PUBLIC_URL}/img/galleries/${name}`}
        items={items}
        focusedIdx={focusedIdx}
        backgroundSize="contain"
      />
      <Rolodex
        items={items}
        focusedIdx={focusedIdx}
        prevSlide={() => prev(name)}
        nextSlide={() => next(name)}
      />
      <span className="fullscreen" onClick={() => open(name)}>
        <Fullscreen />
      </span>
    </div>
  </div>
)

const mapStateToProps = ({galleries}, {name}) => galleries[name]

export default connect(mapStateToProps, {prev, next, open})(Gallery)
