import React from 'react'
import Gallery from '../../containers/Gallery'
import Company from '../../containers/ConnectedCompany'
import TextLink from '../../components/TextLink'

const Inhouse = () => (
  <Company name="Inhouse">
    <p>My good friend Chet set up an events company in 2009, and now operates in more than 20 venues and has over 80 staff. I was asked to join the company in a short-term role to overhaul their online processes.</p>
    <p>I refreshed their <strong>branding</strong> and <TextLink href="http://inhouse.scot">public facing site</TextLink>, and upgraded their PHP-based <strong>staff management suite</strong>, built a <strong>white-labeled venue booking system</strong> in Laravel, and helped modernise the Artschool Degree Show ticketing process using <strong>Eventbrite</strong> and <strong>QR Codes</strong>.</p>
    <p>Something I found very rewarding during this time was <strong>training</strong> a young developer in their team to maintain my work.  Over the course of a few months I developed lessons and demo projects: from the basics of arrays and objects; through OOP concepts and techniques; dependency management to creating and maintaining Laravel sites, using preprocessors like Gulp, and deploying sites with Git.</p>

    <h4>Highlight: <TextLink href="http://inhouse.scot">the Inhouse website</TextLink></h4>
    <Gallery name="inhouse" />
    <p>After some meetings with the management team, it was clear they wanted a memorable website which could help generate buzz around the business.  Their favourite projects are street festivals, and since the company is called Inhouse, we came up with the scene of a house with windows depicting their services, and a street party going on out front.</p>
    <p>Using keyframe animator <strong>Spine</strong>, I created a skeleton with various on-the-spot animations, then using <strong>WebGL</strong> renderer <strong>Pixi.js</strong> I mapped 40+ character sprites to the master skeleton, and positioned the characters.</p>
    <p>The site gets positive comments practically every day from clients and employees alike.</p>
  </Company>
)

export default Inhouse
