export default {
  focusedIdx: 0,
  isDecrement: false,
  items: [
    {
      year: 1990,
      img: '1990.jpg',
      text: `My Mum used to bring Macintosh Classics home from school at the weekend. I made Christmas and Birthday cards in Claris Draw and Paint. I preferred Draw.`,
    },
    {
      year: 1999,
      img: '1999.jpg',
      text: `I made a website for my band in 1999. I learned what I could by inspecting the code of the legendary Green Plastic Radiohead fansite.`,
    },
    {
      year: 2000,
      img: '2000.jpg',
      text: `After my exams, I made my next website for my brothers business Surface-ID. I got paid for it too!`,
    },
    {
      year: 2002,
      img: '2002.jpg',
      text: `I had a gap year after high school in the Australian Outback.`,
    },
    {
      year: 2003,
      img: '2003.jpg',
      text: `I declined my position in Philosophy at Dundee University to start a career as a freelance web designer.`,
    },
    {
      year: 2005,
      img: '2005.jpg',
      text: `In 2005 I formed a business with a developer friend called Silicon Sweet. My role was design.`,
    },
    {
      year: 2008,
      img: '2008.jpg',
      text: `In late 2008 I started working for Alienation Digital.`,
    },
    {
      year: 2012,
      img: '2012.jpg',
      text: `I left Alienation to travel the world with my girlfriend Sazia.`,
    },
    {
      year: 2013,
      img: '2013.jpg',
      text: `After travelling in Asia for 10 months, we arrived in Melbourne and I worked for Arkade as a frontend developer for 8 months.`,
    },
    {
      year: 2014,
      img: '2014.jpg',
      text: `In October, 2 years and 100 Mondays later we arrived back to Glasgow, and I was quickly put back to work by Arkade.`,
    },
    {
      year: 2015,
      img: '2015.jpg',
      text: `In February I left Arkade to help overhaul the online aspects of my friends events company.`,
    },
    {
      year: 2017,
      img: '2017.jpg',
      text: `I was offered a job at an R&D startup in Edinburgh called KPV Lab, and was honored to work alongside ex-Googlers and PhD graduates.`,
    },
    {
      year: 2018,
      img: '2018.jpg',
      text: `My son Evan was born on the 27th of February.`,
    }
  ]
}
