import React from 'react'
import {Swipeable} from 'react-swipeable'
import {ArrowLeft, ArrowRight} from '../Icons'
import './index.sass'

const Rolodex = (props) => (
  <Swipeable
    className="rolodex"
    onSwipedLeft={() => handleNext(props)}
    onSwipedRight={() => handlePrev(props)}>
    <ul>
      {props.items.map((item, i) => (
        <li key={i} className={getItemClasses(props.focusedIdx, i)}>
          <p>{item.text}</p>
        </li>
      ))}
    </ul>
    <span
      className={'arrow prev' + (hasPrev(props) ? '' : ' disabled')}
      onClick={() => handlePrev(props)}>
      <span><ArrowLeft /></span>
    </span>
    <span
      className={'arrow next' + (hasNext(props) ? '' : ' disabled')}
      onClick={() => handleNext(props)}>
      <span><ArrowRight /></span>
    </span>
  </Swipeable>
)

function getItemClasses(focusedIdx, idx) {
  if(idx === focusedIdx - 1)
    return 'prev'
  else if(idx === focusedIdx)
    return 'active'
  else if(idx === focusedIdx + 1)
    return 'next'
  return ''
}

function handlePrev(props) {
  const {prevSlide} = props
  if(hasPrev(props)) {
    prevSlide && prevSlide()
  }
}

function handleNext(props) {
  const {nextSlide} = props
  if(hasNext(props)) {
    nextSlide && nextSlide()
  }
}

function hasPrev(props) {
  const {focusedIdx} = props
  return focusedIdx > 0
}

function hasNext(props) {
  const {items, focusedIdx} = props
  return focusedIdx < items.length - 1
}

export default Rolodex
