import {format} from 'date-fns'

const DATE_FORMAT = 'do MMMM yyyy'
const DURATION_FORMAT = 'MMM yyyy'
const LANG_PRESENT = 'Present'

export const date = (date) => (
  format(new Date(date), DATE_FORMAT)
)

export const duration = (startDate, endDate) => {
  startDate = format(new Date(startDate), DURATION_FORMAT)
  endDate = endDate
    ? format(new Date(endDate), DURATION_FORMAT)
    : LANG_PRESENT
  return `${startDate} - ${endDate}`
}
