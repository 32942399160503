export default {
  active: 'intro',
  opened: false,
  hamburgerVisible: true,
  items: [
    {
      name: 'intro',
      title: 'Intro',
      colour: 'purple',
    },
    {
      name: 'key-facts',
      title: 'Key Facts',
      colour: 'red',
    },
    {
      name: 'timeline',
      title: 'Timeline',
      colour: 'blue',
    },
    {
      name: 'employment',
      title: 'Employment',
      colour: 'orange',
    },
    {
      name: 'personal-projects',
      title: 'Personal Projects',
      colour: 'pink',
    },
    {
      name: 'key-skills',
      title: 'Key Skills',
      colour: 'turquoise',
    },
    // {
    //   name: 'outro',
    //   title: 'Outro',
    //   colour: 'red',
    // }
  ]
}
