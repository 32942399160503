export default [
  {
    name: 'KPV Lab Edinburgh',
    startDate: '2017-05-22',
    endDate: false,
    website: 'https://kpv-lab.co.uk',
    reference: {
      name: 'Tom Beddard',
      email: 'tb@kpv-lab.co.uk',
      position: 'Director at KPV Lab Edinburgh'
    }
  },
  {
    name: 'Inhouse',
    startDate: '2015-02-14',
    endDate: '2016-12-31',
    website: 'http://inhouse.scot',
    // reference: {
    //   name: 'Cetin Capkiner',
    //   email: 'chet@inhouse.scot',
    //   position: 'Director at Inhouse'
    // }
  },
  {
    name: 'Arkade',
    startDate: '2013-09-01',
    endDate: '2015-02-13',
    website: 'http://arkade.com.au',
    // reference: {
    //   name: 'Danny Philips',
    //   email: 'danny@arkade.com.au',
    //   position: 'Director at Arkade'
    // }
  },
  {
    name: 'Alienation Digital',
    startDate: '2008-11-01',
    endDate: '2012-09-30',
    website: 'https://afterdigital.co.uk',
    // reference: {
    //   name: 'Aidan Martin',
    //   email: 'mraidanmartin85@hotmail.co.uk',
    //   position: 'Senior Designer during my time at Alienation'
    // }
  }
]
