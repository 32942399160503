import React from 'react'
// import Gallery from '../../containers/Gallery'
import Company from '../../containers/ConnectedCompany'
import TextLink from '../../components/TextLink'

const Arkade = () => (
  <Company name="Arkade">
    <p>I worked for 8 months in Melbourne for <TextLink href="https://www.arkade.com.au">Arkade</TextLink>, and then briefly again when I returned home to help them through a large frontend project.</p>
    <p>At Arkade my role as lead frontend-developer involved managing a small team, making <strong>bespoke D3 visualisations</strong> of huge amounts of loyalty data for large retail clients.  I was also tasked with rapidly launching lots of small campaign <strong>Laravel</strong> sites for capturing user data, like competitions and quizzes with the chance of winning a prize.</p>
    <p>I gained experience working with some of Australia's leading fashion and cosmetics brands like <strong>Georgio Armani</strong>, <strong>L'Oreal</strong>, <strong>Kiehl's</strong>, <strong>Witchery</strong> and <strong>Cotton:On</strong>, and led the development of <strong>Mailchimp</strong> campaigns sent out to millions of subscribers.</p>
    <h4>Highlight: <TextLink href="http://designcentre.gliderol.com.au">Gliderol Design Centre</TextLink></h4>
    {/* <Gallery name="gliderol" /> */}
    <p>When I arrived home from travelling I was asked if I could make a "garage door designer" for the world's largest garage door supplier.  The idea was to allow the user to take a photo of their house and garage on their iPad, then use the Design Centre to preview different designs directly on their own home.</p>
    <p>This <strong>SPA</strong> project has a few interesting components: it allows users to upload and crop hi-res photos instantly (as it's all kept in-browser); includes a bespoke algorithm to generate <strong>3D matrix transforms</strong> using 2D grab handles; a <strong>bespoke colour-shading algorithm</strong> to emboss tiles onto various light and dark backgrounds and a Laravel micro-service for generating PDFs of their designs to take to a dealer, which uses <strong>PhantomJS</strong> behind-the-scenes.</p>
  </Company>
)

export default Arkade
