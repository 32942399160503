import React from 'react'
import Company from '../../containers/ConnectedCompany'
import TextLink from '../../components/TextLink'

const KPVLab = () => (
  <Company name="KPV Lab Edinburgh">
    <p>
      I was priveleged to work with Tom at his R&D "stealth startup" as developer No. 3.  At the time, we were based in a dingy attic space down a cobbled lane, and 2 moves later we occupy one of the <TextLink href="https://www.insider.co.uk/news/german-tech-company-open-first-11860038">best real estate locations</TextLink> in the Capital.
    </p>
    <p>
      Unfortunately I can't discuss the projects I worked on due to a NDA, but our stack was React, Node.js, GraphQL, Jest, Koa, Postgres and Heroku. My role was as a full-stack developer.  Working at the bleeding edge, a large portion of my job was spent researching and reporting back to the team - I present research on technologies to our team on <strong>SVG</strong>, <strong>GraphQL</strong>, <strong>Musicbrainz</strong>, <strong>ffmpeg</strong> and <strong>advanced Postgres features</strong>.  I also occasionally travel to Germany to liaise with our sister company based in Berlin.
    </p>
    <p>
      The academic, methodical approach introduced to me here dramatically increased my problem solving skills.  I also learned advanced math techniques to solve 2D and 3D data visualisation problems.
    </p>
    <p>
      However, commuting from Glasgow to Edinburgh along with the unpredictable responsibilities of parenthood, means I am now looking for new opportunities closer to home.
    </p>
  </Company>
)

export default KPVLab
