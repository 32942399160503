import React from 'react'
import {Scrub} from '../Icons'
import './index.sass'

const RangeSlider = ({min, max, step, value, onChange}) => (
  <div className="range-slider">
    <input
      type="range"
      min={min}
      max={max}
      step={step}
      onChange={handleChange.bind(null, value, onChange)}
    />
    <div className="range-thumb" style={leftOffset(value, min, max)}>
      <Scrub fill="#ccc" />
    </div>
  </div>
)

function leftOffset(value, min, max) {
  const val = (value - min) / (max - min) * 100
  return {
    left: `${val}%`
  }
}

function handleChange(oldValue, cb, e) {
  const value = parseInt(e.target.value, 10)
  if(value === oldValue) return
  cb && cb(value)
}

export default RangeSlider
