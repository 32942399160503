import React from 'react'
import './index.sass'

const Quote = ({thumb, alt, text}) => (
  <blockquote>
    {thumb &&
      <div className="thumb">
        <img src={thumb} alt={alt} />
      </div>}
    <span className="quote">{text}</span>
  </blockquote>
)

export default Quote
