import React from 'react'

export const ArrowLeft = ({fill = '#367ddc'}) => (
  <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
    <path fill={fill} d="M12.452,4.516c0.446,0.436,0.481,1.043,0,1.576L8.705,10l3.747,3.908c0.481,0.533,0.446,1.141,0,1.574
	c-0.445,0.436-1.197,0.408-1.615,0c-0.418-0.406-4.502-4.695-4.502-4.695C6.112,10.57,6,10.285,6,10s0.112-0.57,0.335-0.789
	c0,0,4.084-4.287,4.502-4.695C11.255,4.107,12.007,4.08,12.452,4.516z"/>
  </svg>
)

export const ArrowRight = ({fill = '#367ddc'}) => (
  <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
    <path fill={fill} d="M9.163,4.516c0.418,0.408,4.502,4.695,4.502,4.695C13.888,9.43,14,9.715,14,10s-0.112,0.57-0.335,0.787
	c0,0-4.084,4.289-4.502,4.695c-0.418,0.408-1.17,0.436-1.615,0c-0.446-0.434-0.481-1.041,0-1.574L11.295,10L7.548,6.092
	c-0.481-0.533-0.446-1.141,0-1.576C7.993,4.08,8.745,4.107,9.163,4.516z"/>
  </svg>
)

export const Close = ({fill = '#367ddc'}) => (
  <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.997 20">
    <path fill={fill} d="M0.574,16.537c0.739-0.738,8.499-7.946,8.499-7.946C9.469,8.198,9.985,8,10.5,8
		c0.516,0,1.032,0.198,1.425,0.592c0,0,7.764,7.208,8.498,7.946c0.738,0.738,0.789,2.064,0,2.85c-0.785,0.787-1.885,0.85-2.85,0
		L10.5,12.773l-7.074,6.613c-0.965,0.85-2.065,0.787-2.853,0C-0.215,18.602-0.167,17.275,0.574,16.537z"/>
    <path fill={fill} d="M0.574,3.464c0.739,0.737,8.499,7.946,8.499,7.946c0.396,0.393,0.912,0.592,1.428,0.592
		c0.516,0,1.032-0.199,1.425-0.592c0,0,7.764-7.208,8.498-7.946c0.738-0.738,0.789-2.065,0-2.851c-0.785-0.787-1.885-0.85-2.85,0
		L10.5,7.227L3.427,0.614c-0.965-0.85-2.065-0.787-2.853,0C-0.215,1.399-0.167,2.726,0.574,3.464z"/>
  </svg>
)

export const Fullscreen = ({fill = '#367ddc'}) => (
  <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
    <path fill={fill} d="M6.987,10.987l-2.931,3.031L2,11.589V18h6.387l-2.43-2.081l3.03-2.932L6.987,10.987z M11.613,2l2.43,2.081l-3.03,2.932l2,2
	l2.931-3.031L18,8.411V2H11.613z"/>
  </svg>
)

export const Scrub = ({fill = '#367ddc'}) => (
  <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
    <path fill={fill} d="M0,22h4V0H0V22z M18,0v22h4V0H18z M9,22h4V0H9V22z"/>
  </svg>
)
