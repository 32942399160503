import React, {Component} from 'react'
import {findDOMNode} from 'react-dom'
import './index.sass'

const {addEventListener, removeEventListener} = window

let counter = 0
const uniqueRef = () => `canvas-${counter++}`

export default class Canvas extends Component {
  constructor(...props) {
    super(...props)
    this.state = {
      dimensions : {}
    }
    this.wrapperEl = null
    this.setDimensions = this.setDimensions.bind(this)
    this.canvasRef = uniqueRef()
  }

  componentDidMount() {
    this.wrapperEl = findDOMNode(this)
    addEventListener('resize', this.setDimensions, false)
    this.setDimensions()
  }

  componentWillUnmount() {
    removeEventListener('resize', this.setDimensions)
  }

  getCanvas() {
    return this.refs[this.canvasRef]
  }

  getCtx() {
    return this.getCanvas().getContext('2d')
  }

  getDimensions() {
    let {width, height} = this.wrapperEl.getBoundingClientRect()
    const {devicePixelRatio} = window
    width = Math.round(width)
    height = Math.round(height)
    return {
      width: width * devicePixelRatio,
      height: height * devicePixelRatio,
      style: {width, height}
    }
  }

  setDimensions() {
    const dimensions = this.getDimensions()
    const {onResize} = this.props
    this.setState({dimensions}, () => {
      onResize && onResize(dimensions)
    })
  }

  render() {
    const {className} = this.props
    return (
      <div className={'canvas' + (className ? ' ' + className : '')}>
        <canvas ref={this.canvasRef} {...this.state.dimensions} />
      </div>
    )
  }
}

export const toRadians = val => val * (Math.PI / 180)
export const toDegrees = val => val * (180 / Math.PI)

export const easeInQuad = t => t * t
export const easeInCubic = t => t * t * t
export const easeInQuart = t => t * t * t * t
export const easeInQuint = t => t * t * t * t * t

export const easeOutQuad = t => t * (2 - t)
export const easeOutCubic = t => (--t) * t * t + 1
export const easeOutQuart = t => 1 -(--t) * t * t * t
export const easeOutQuint = t => 1 + (--t) * t * t * t * t

export const easeInOutQuad = t => t < .5 ? 2 * t * t : -1 + (4 - 2 * t) * t
export const easeInOutCubic = t => t < .5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1
export const easeInOutQuart = t => t < .5 ? 8 * t * t * t * t : 1 - 8 * (--t) * t * t * t
export const easeInOutQuint = t => t < .5 ? 16 * t * t * t * t * t : 1 + 16 * (--t) * t * t * t * t
