import React, {Component} from 'react'
import debounce from 'lodash/debounce'
import Canvas from '../Canvas'
import * as imgScale from './imgScale'

let counter = 0
const uniqueRef = () => `imageMorph-${counter++}`

export default class ImageMorph extends Component {
  constructor(...props) {
    super(...props)
    const {duration} = this.props
    this.images = []
    this.currentImg = []
    this.oldImg = []
    this.timer = 0
    this.opacity = 0
    this.start = null

    this.preloadImage = this.preloadImage.bind(this)
    this.transitionImage = debounce(this.transitionImage.bind(this), duration, {leading: true})
    this.animateFade = this.animateFade.bind(this)
    this.onResize = this.onResize.bind(this)
    this.uniqueRef = uniqueRef()
  }

  componentDidMount() {
    const canvas = this.refs[this.uniqueRef]
    this.ctx = canvas.getCtx()
    this.dimensions = canvas.getDimensions()
    this.preloadImages()
  }

  componentDidUpdate(prevProps) {
    const {basePath, focusedIdx} = this.props
    if(basePath !== prevProps.basePath) return this.preloadImages()
    if(focusedIdx === prevProps.focusedIdx) return
    this.transitionImage(focusedIdx)
  }

  preloadImages(props = this.props) {
    if(!props.basePath) return this.reset()
    const {items, basePath, focusedIdx} = props
    const imagePaths = items.map((o, idx) => ({
      path: `${basePath}/${o.img}`,
      idx
    }))
    const activeImage = imagePaths[focusedIdx]
    const otherImages = [
      ...imagePaths.slice(0, focusedIdx),
      ...imagePaths.slice(focusedIdx + 1)
    ]

    return Promise.resolve(activeImage)
      .then(this.preloadImage)
      .then(this.transitionImage)
      .then(() => (
        Promise.all(otherImages.map(this.preloadImage))
      ))
  }

  preloadImage({path, idx}) {
    return new Promise((res, rej) => {
      const img = new Image()
      img.addEventListener('error', () => {
        rej(new Error(`Loading image ${path}`))
      })
      img.addEventListener('load', () => {
        this.images[idx] = img
        res(idx)
      })
      img.src = path
    })
  }

  resize(img) {
    if(!img) return
    const {backgroundSize} = this.props
    return imgScale[backgroundSize](this.dimensions, img)
  }

  transitionImage(focusedIdx) {
    this.oldImg = this.currentImg
    this.currentImg = this.resize(this.images[focusedIdx])
    this.animateFade()
  }

  animateFade(delta) {
    if(delta) {
      if(this.timer === 0) {
        this.start = delta
        this.timer = (delta - this.start) + 0.0001
      } else {
        this.timer = delta - this.start
      }
    }

    if(this.timer > this.props.duration) {
      this.timer = 0
      this.opacity = 0
      return
    }

    this.draw(this.oldImg, 1 - this.opacity)
    this.draw(this.currentImg, this.opacity)

    this.opacity = this.timer / this.props.duration
    requestAnimationFrame(this.animateFade)
  }

  draw(img, opacity) {
    if(!Array.isArray(img) || !img.length) return
    this.ctx.save()
    this.ctx.globalAlpha = opacity
    this.ctx.drawImage(...img)
    this.ctx.restore()
  }

  onResize(dimensions) {
    this.dimensions = dimensions
    const [img] = this.currentImg
    this.draw(this.resize(img))
  }

  reset() {
    const {width, height} = this.dimensions
    this.currentImg = []
    setTimeout(() => {
      this.ctx.clearRect(0, 0, width, height)
    }, 600)
  }

  render() {
    return <Canvas className="image-morph" ref={this.uniqueRef} onResize={this.onResize} />
  }
}

ImageMorph.defaultProps = {
  duration: 400,
  backgroundSize: 'cover'
}
