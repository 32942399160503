import React from 'react'
import {connect} from 'react-redux'
import find from 'lodash/find'
import Company from '../../components/Company'
import {duration} from '../../utils/time'

const ConnectedCompany = ({name, startDate, endDate, children}) => (
  <Company name={name} duration={duration(startDate, endDate)}>
    {children}
  </Company>
)

const mapStateToProps = ({employment}, {name}) => ({
  ...find(employment, {name})
})

export default connect(mapStateToProps)(ConnectedCompany)
