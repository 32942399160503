import React from 'react'
// import Gallery from '../../containers/Gallery'
import Company from '../../containers/ConnectedCompany'
// import TextLink from '../../components/TextLink'

const AlienationDigital = () => (
  <Company name="Alienation Digital">
    <p>I cut my teeth as a designer and frontend-developer at Alienation Digital (now After Digital), and saw the company grow from 6 people in a tiny office in Anderston to over 30 people in a swanky office at Charing Cross.</p>
    <p>My role involved leading clients through our design process, from <strong>wireframed mockups</strong> to completion and sign-off, then building those designs into responsive <strong>Joomla templates</strong> to pass to the PHP development team.  As a long-standing member of the team, I became the point-of-contact for some of their biggest clients like <strong>Vango</strong>, <strong>If Only</strong> and <strong>Bath University</strong>.</p>
  </Company>
)

export default AlienationDigital
