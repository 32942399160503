import React from 'react'
import {connect} from 'react-redux'
import {differenceInYears} from 'date-fns'
import Section from '../../containers/ScrollSection'
import {date} from '../../utils/time'

const KeyFacts = ({dob, age, email, bornAndRaised, currentLocale, lastEmployer, references}) => (
  <Section name="key-facts">
    <h2>Key Facts</h2>
    <dl>
      <dt>Date of birth</dt>
      <dd>{dob}</dd>
      <dt>Age</dt>
      <dd>{age}</dd>
      <dt>Email</dt>
      <dd><a className="text-link" href={`mailto:${email}`}>{email}</a></dd>
      <dt>Born and raised</dt>
      <dd>{bornAndRaised}</dd>
      <dt>Current locale</dt>
      <dd>{currentLocale}</dd>
      <dt>Last Employer</dt>
      <dd>
        <a className="text-link" href={lastEmployer.website} target="_blank" rel="noopener noreferrer">
          {lastEmployer.name}
        </a>
      </dd>
      <dt>References</dt>
      <dd>
        <ul>
          {references.map((reference, i) => (
            <li key={i}>
              <a className="text-link" href={`mailto:${reference.email}`}>{reference.name}</a><br />
              <span className="subtle">({reference.position})</span>
            </li>
          ))}
        </ul>
      </dd>
    </dl>
  </Section>
)

const mapStateToProps = ({keyFacts, employment}) => {
  const sortedEmployers = employment.sort((a, b) => b.endDate - a.endDate)
  const age = differenceInYears(new Date(), new Date(keyFacts.dob))
  return {
    dob: date(keyFacts.dob),
    age,
    email: keyFacts.email,
    bornAndRaised: keyFacts.bornAndRaised,
    currentLocale: keyFacts.currentLocale,
    lastEmployer: sortedEmployers[0],
    references: sortedEmployers.map(o => o.reference).filter(Boolean)
  }
}

export default connect(mapStateToProps)(KeyFacts)
