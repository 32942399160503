import assign from 'lodash/assign'
import initialState from '../data/timeline'

export default (state = initialState, action) => {
  switch(action.type) {
    case 'TIMELINE_SET_FOCUSED_IDX':
      return assign({}, state, {
        focusedIdx: action.focusedIdx,
        isDecrement: action.focusedIdx < state.focusedIdx
      })
    case 'TIMELINE_INCREMENT_FOCUSED_IDX':
      return assign({}, state, {
        focusedIdx: state.focusedIdx + 1,
        isDecrement: false
      })
    case 'TIMELINE_DECREMENT_FOCUSED_IDX':
      return assign({}, state, {
        focusedIdx: state.focusedIdx - 1,
        isDecrement: true
      })
    default:
      return state
  }
}

export const incrementFocusedIdx = () => ({
  type: 'TIMELINE_INCREMENT_FOCUSED_IDX'
})

export const decrementFocusedIdx = () => ({
  type: 'TIMELINE_DECREMENT_FOCUSED_IDX'
})

export const setFocusedIdx = (focusedIdx) => ({
  type: 'TIMELINE_SET_FOCUSED_IDX',
  focusedIdx
})
