import React from 'react'
import {connect} from 'react-redux'
import {Waypoint} from 'react-waypoint'
import range from 'lodash/range'
import {isVisible, isHidden} from '../../ducks/carRace'
import './index.sass'

const CarRace = ({visible, isVisible, isHidden}) => (
  <Waypoint scrollableAncestor={window} onEnter={isVisible} onLeave={isHidden}>
    <div className={'car-race' + (visible ? ' visible' : '')}>
      <div className="wrap">
        <div className="clockwise fill">
          <Track />
          <Trees num="1" />
          <Trees num="2" />
        </div>
        <Cars />
        <StarCar />
      </div>
    </div>
  </Waypoint>
)

const Track = () => (
  <div className="track circle fill">
    <div className="stripe circle fill" />
    <div className="checker-board" />
    <div className="tred circle fill" />
    <div className="inner tred circle fill">
      {/* <Message /> */}
    </div>
  </div>
)

// function useClientRect(ref) {
//   const [rect, setRect] = React.useState(null);
//   React.useLayoutEffect(() => {
//     function handleResize() {
//       if (ref.current) {
//         setRect(ref.current.getBoundingClientRect())
//       }
//     }
//     handleResize()
//     window.addEventListener('resize', handleResize, true)
//     return () => {
//       window.removeEventListener('resize', handleResize)
//     }
//   }, [ref])
//   return rect;
// }

// const Message = () => {
//   const ref = React.useRef(null)
//   const rect = useClientRect(ref)
//   console.log(rect)
//   const width = rect ? Math.round(rect.width) : 100
//   const cx = width / 2
//   const cy = width / 2
//   const radius = (width / 2) * 0.875
//   return (
//     <svg ref={ref} className="message fill" viewBox={`0 0 ${cx * 2} ${cy * 2}`}>
//       {rect && (
//         <React.Fragment>
//           <defs>
//             <path id="ring" d={`
//               M ${cx} ${cy + radius}
//               A ${radius} ${radius} 0 1 1 ${cx} ${cy - radius} 
//               A ${radius} ${radius} 0 1 1 ${cx} ${cy + radius} 
//             `} />
//           </defs>
//           <text textAnchor="middle">
//             <textPath href="#ring" startOffset="50%">
//               Thanks for your time!
//             </textPath>
//           </text>
//         </React.Fragment>
//       )}
//     </svg>
//   )
// }

const Trees = ({num}) => (
  <div className={`trees trees${num} circle fill`}>
    {range(8).map((i) => (
      <div key={i} className="tree circle fill">
        <div />
      </div>
    ))}
  </div>
)

const Cars = () => (
  <div className="cars fill">
    {range(8).map((i) => (
      <div key={i} className="route circle fill">
        <div />
      </div>
    ))}
  </div>
)

const StarCar = () => (
  <div className="starcar fill">
    <div className="route circle fill">
      <div />
    </div>
  </div>
)

const mapStateToProps = ({carRace}) => ({
  visible: carRace.visible
})

export default connect(mapStateToProps, {isVisible, isHidden})(CarRace)
