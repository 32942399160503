import React, {Component} from 'react'
import {Helmet} from 'react-helmet-async'
import {Swipeable} from 'react-swipeable'
import {connect} from 'react-redux'
import find from 'lodash/find'
import Hamburger from '../../components/Hamburger'
import ScrollLink from '../../components/ScrollLink'
import {open, close} from '../../ducks/nav'
import './index.sass'

class Nav extends Component {
  state = {
    top: true
  }
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, true)
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, true)
  }
  handleScroll = (e) => {
    const scroll = e.target.documentElement.scrollTop
    this.setState(({top}) => {
      if (top && scroll > 0) {
        return {top: false}
      }
      if (!top && scroll === 0) {
        return {top: true}
      }
    })
  }
  render() {
    const {name, items, active, activeColour, opened, open, close} = this.props
    const {top} = this.state
    return (
      <Swipeable
        className="nav"
        onClick={close}
        onSwipedLeft={close}>
        <Helmet>
          <body className={opened ? 'nav-open' : ''} />
        </Helmet>
        <nav>
          <ul className="scroll-links">
            {items.map((item, i) => (
              <li key={i} className={item.name === active ? ('active ' + activeColour) : ''}>
                <ScrollLink href={item.name} onClick={close}>
                  {item.title}
                </ScrollLink>
              </li>
            ))}
          </ul>
        </nav>
        <header className={top ? 'top' : ''}>
          <Hamburger
            className={window.scrollTop > 0 ? 'hide' : ''}
            onClick={(e) => {
              e.stopPropagation()
              opened ? close() : open()
            }}
          />
          <h1>{name}</h1>
        </header>
        <Swipeable className="strip" onSwipedRight={open} />
      </Swipeable>
    )
  }
}

const mapStateToProps = ({nav, keyFacts}) => ({
  ...nav,
  activeColour: find(nav.items, {name: nav.active}).colour,
  name: keyFacts.name
})

export default connect(mapStateToProps, {open, close})(Nav)
