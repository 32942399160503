import React from 'react'
import {connect} from 'react-redux'
import {differenceInYears} from 'date-fns'
import {setFocusedIdx, incrementFocusedIdx, decrementFocusedIdx} from '../../ducks/timeline'
import Section from '../../containers/ScrollSection'
import ImageMorph from '../../components/ImageMorph'
import Rolodex from '../../components/Rolodex'
import RangeSlider from '../../components/RangeSlider'
import Ticks from '../../components/Ticks'
import './index.sass'

const {PUBLIC_URL} = process.env

const Timeline = ({
  items, focusedIdx, isDecrement,
  setFocusedIdx, incrementFocusedIdx, decrementFocusedIdx
}) => (
  <Section name="timeline" className="boxout">
    <h2>Timeline</h2>
    <div className="wrap">
      <ImageMorph
        basePath={`${PUBLIC_URL}/img/timeline`}
        items={items}
        focusedIdx={focusedIdx}
      />
      <Rolodex
        items={items}
        focusedIdx={focusedIdx}
        prevSlide={decrementFocusedIdx}
        nextSlide={incrementFocusedIdx}
      />
      <Ticks
        items={items}
        focusedIdx={focusedIdx}
        isDecrement={isDecrement}
        onClick={setFocusedIdx}
      />
      <RangeSlider
        min={0}
        max={items.length - 1}
        step={1}
        value={focusedIdx}
        onChange={setFocusedIdx}
      />
    </div>
  </Section>
)

const mapStateToProps = ({timeline, keyFacts}) => {
  const {items} = timeline
  timeline.items = items.map(item => {
    item.age = differenceInYears(new Date(item.year), new Date(keyFacts.dob))
    return item
  })
  return {...timeline}
}

const mapPropsToDispatch = {setFocusedIdx, incrementFocusedIdx, decrementFocusedIdx}

export default connect(mapStateToProps, mapPropsToDispatch)(Timeline)
