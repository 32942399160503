
export const cover = (container, img) => {
  const w = container.width
  const h = container.height
  const iw = img.width
  const ih = img.height
  const scale = 1
  const offsetX = 0.5
  const offsetY = 0.5
  const r = Math.min(w / iw, h / ih)
  let nw = iw * r
  let nh = ih * r
  let cx, cy, cw, ch, ar = 1

  if (nw < w) ar = w / nw
  if (Math.abs(ar - 1) < 1e-14 && nh < h) ar = h / nh
  nw *= ar
  nh *= ar
  cw = (iw / (nw / w)) * scale
  ch = (ih / (nh / h)) * scale
  cx = (iw - cw) * offsetX
  cy = (ih - ch) * offsetY

  if (cx < 0) cx = 0
  if (cy < 0) cy = 0
  if (cw > iw) cw = iw
  if (ch > ih) ch = ih

  return [img, cx, cy, cw, ch, 0, 0, w, h]
}

export const contain = (container, img) => {
  const w = container.width
  const h = container.height
  const iw = img.width
  const ih = img.height
  const scale = 1
  const offsetX = 0.5
  const offsetY = 0.5
  const r = Math.max(iw / w, ih / h)
  let nw = iw / r
  let nh = ih / r
  let cx, cy, cw, ch, ar = 1

  if (nw > w) ar = nw / w
  if (Math.abs(ar - 1) < 1e-14 && nh > h) ar = nh / h
  nw *= ar
  nh *= ar
  cw = (iw / (nw / w)) * scale
  ch = (ih / (nh / h)) * scale
  cx = (iw - cw) * offsetX
  cy = (ih - ch) * offsetY

  if (cx > 0) cx = 0
  if (cy > 0) cy = 0
  if (cw < iw) cw = iw
  if (ch < ih) ch = ih

  return [img, cx, cy, cw, ch, 0, 0, w, h]
}
