import React from 'react'
import Section from '../../containers/ScrollSection'
import './index.sass'

const KeySkills = () => (
  <Section name="key-skills">
    <h2>Key Skills</h2>
    <ul>
      <li>General knowledge and enthusiasm</li>
      <li>Javascript 'full stack' development</li>
      <li>Working knowledge of various data analysis and visualisation techniques</li>
      <li>Strong UI/UX skills</li>
      <li>Extensive experience with Adobe CC</li>
    </ul>
  </Section>
)

export default KeySkills
