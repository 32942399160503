import React from 'react'
import {scroller} from 'react-scroll'

const ScrollLink = ({href, children, onClick, ...props}) => (
  <span className="text-link" onClick={() => handleClick(href, onClick)} {...props}>
    {children}
  </span>
)

function handleClick(name, onClick) {
  const options = {
    duration: 750,
    smooth: true,
  }
  if (window.innerWidth < 768) {
    options.offset = -65
  }
  scroller.scrollTo(name, options)
  onClick && onClick(name)
}

export default ScrollLink
