import React from 'react'

const TextLink = ({href, children, ...props}) => (
  <a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    className="text-link"
    {...props}>
    {children}
  </a>
)

export default TextLink
