import React from 'react'
import './index.sass'

const Company = ({name, duration, children}) => (
  <div className="company">
    <div className="title">
      <h3>{name}</h3>
      <span className="subtle">{duration}</span>
    </div>
    {children}
  </div>
)

export default Company
