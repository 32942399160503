import React from 'react'
import {connect} from 'react-redux'
import Section from '../../containers/ScrollSection'
import Quote from '../../components/Quote'
import einstein from './einstein.jpg'
import './index.sass'

const Intro = ({name}) => (
  <Section name="intro">
    <header>
      {/* <h1>{name}</h1> */}
      <h4>Developer, designer and perpetual <span className="strike">amateur</span> genius</h4>
    </header>
    <p>
      I'm an experienced and passionate end-to-end developer, designer and problem solver based in Glasgow, Scotland. I specialise in building web apps, data visualisation and fancy frontend sprinkles. I have in-depth knowledge of libraries such as <strong>React</strong>, <strong>Koa</strong>, <strong>Postgres</strong>, <strong>Electron</strong>, <strong>MapboxGL</strong> and <strong>Pixi.js</strong>.
    </p>
    <Quote thumb={einstein} alt="Einstein" text="Make things as simple as possible, but no simpler." />
  </Section>
)

const mapStateToProps = ({keyFacts}) => ({
  name: keyFacts.name
})

export default connect(mapStateToProps)(Intro)
