import React from 'react'
import './index.sass'

const Hamburger = ({onClick, className = ''}) => (
  <div className={'hamburger ' + className} onClick={onClick}>
    <span />
    <span />
    <span />
    <span />
  </div>
)

export default Hamburger
