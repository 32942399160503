import assign from 'lodash/assign'

export default (state = {visible: false}, action) => {
  switch(action.type) {
    case 'CAR_RACE_IS_VISIBLE':
      return assign({}, state, {visible: true})
    case 'CAR_RACE_IS_HIDDEN':
      return assign({}, state, {visible: false})
    default:
      return state
  }
}

export const isVisible = () => ({
  type: 'CAR_RACE_IS_VISIBLE'
})

export const isHidden = () => ({
  type: 'CAR_RACE_IS_HIDDEN'
})
