import React, {Fragment} from 'react'
import {Helmet} from 'react-helmet-async'

import Nav from './containers/Nav'
import Intro from './views/Intro'
import KeyFacts from './views/KeyFacts'
import Timeline from './views/Timeline'
import Employment from './views/Employment'
import PersonalProjects from './views/PersonalProjects'
import KeySkills from './views/KeySkills'
import CarRace from './views/CarRace'
import Lightbox from './containers/Lightbox'
import './index.sass'

const App = () => (
  <Fragment>
    <Helmet>
      <title>David McArthur / CV</title>
    </Helmet>
    <Nav />
    <div className="container">
      <Intro />
      <KeyFacts />
      <Timeline />
      <Employment />
      <PersonalProjects />
      <KeySkills />
    </div>
    <CarRace />
    <Lightbox />
  </Fragment>
)

export default App
